input {
  width: max-content;
  height: 40px;
  padding: 3px 15px;
  border-radius: 10px;
  outline: none;
  border: 1px solid $light_gray;
  font-size: 16px;
}

button,
.btn {
  color: $white;
  background-color: $dark_blue;
  border: none;
  font-family: $font_title;
  padding: 5px 10px;
  border-radius: 10px;
  height: 40px;
  width: fit-content;
  outline: none;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  &.big {
    // font-weight: bold;
    font-size: 1rem;
  }

  &.green {
    color: $dark_blue;
    background-color: $light_blue;
  }
}
