html,
body {
  margin: 0;
  padding: 0;
  font-family: $font_body;
  color: $dark_blue;
  width: 100%;
}

h1 {
  font-family: $font_title;
}

h2,
h3 {
  font-family: $font_body;
}

a,
a:visited {
  color: $blue;

  &.button {
    color: $white;
  }
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}
