.modal-backdrop {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 99999;

  .modal {
    opacity: 1;
    z-index: 9999;
    position: fixed;
    top: 10%;
    left: 30%;
    width: calc(40% - 80px);
    margin: 0 auto;
    background-color: $white;
    border: 1px solid $very_light_blue;
    border-radius: 10px;
    padding: 40px;
    text-align: center;
    overscroll-behavior-y: contain;
    max-height: calc(80% - 80px);
    overflow-y: scroll;

    .close-btn {
      position: fixed;
      right: 28%;
      top: 8%;
      border-radius: 50px;
      width: 40px;
      height: 40px;
      background-color: $dark_blue;
      color: $very_light_blue;
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      align-items: center;

      input {
        margin-bottom: 15px;
        width: 100%;
        max-width: 300px;
      }
      .message {
        color: $dark_blue;
      }
    }
  }
}

@media screen and (max-width: $tablet_size) {
  .modal-backdrop {
    .modal {
      top: 10%;
      left: 5%;
      width: calc(90% - 100px);
      padding: 50px;

      .btn-content input {
        width: auto;
      }

      .close-btn {
        right: 3%;
      }
    }
  }
}
