// Message
.chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;

  .chatbox-content {
    width: 100%;
    min-width: 300px;
    max-width: 500px;
    padding: 20px;

    .chat-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: scroll;
      max-height: 800px;
      margin-bottom: 20px;

      .msg-content {
        width: 100%;
        display: flex;
        color: $black;

        &.msg-from-user {
          flex-direction: row-reverse;
          .msg-text {
            background-color: $light_blue;
            color: $dark_blue;
            text-align: right;
          }
        }

        .msg-text {
          background-color: $light_gray;
          padding: 5px 15px;
          margin-top: 10px;
          border-radius: 15px;
          width: fit-content;
        }
      }
    }

    .writing {
      font-size: 15px;
      color: $dark_blue;
      padding-left: 5px;
    }

    .control-content {
      display: flex;
      flex-direction: row;
      margin-top: 15px;
      width: 100%;

      .message {
        padding: 5px 15px;
        height: 40px;
        margin-right: 10px;
        width: 100%;
      }

      .btn-chat {
        margin-top: 0px;
        height: 40px;
        font-size: 15px;
        border-radius: 10px;
        padding: 10px;
        font-weight: bold;
        height: 52px;
        width: 100px;
      }
    }
  }
}
