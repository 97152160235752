.header-box {
  background-image: linear-gradient(to bottom, $white, $light_blue) !important;
  padding: 20px;
  padding-top: 30px;

  .header-logo {
    width: 280px;
  }

  .header-content {
    display: flex;
    flex-direction: row;

    .header-left {
      width: 50%;
      text-align: center;

      img {
        // width: calc(100% - 100px);
        margin: 50px;
        max-height: 400px;
      }
    }

    .header-right {
      width: 50%;
      color: $white;
      padding: 50px;

      h1 {
        font-size: 2.2rem;
        color: $dark_blue;
      }
      h3 {
        font-size: 1.4rem;
        font-weight: normal;
        color: $dark_blue;
      }

      .btn-content {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        input {
          margin-bottom: 15px;
          width: 100%;
          max-width: 300px;
        }

        .message,
        .error {
          color: $dark_blue;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet_size) {
  .header-box {
    .header-content {
      flex-direction: column;
      text-align: center;

      .header-left {
        width: 100%;
        .header-img {
          margin: 10px;
          max-height: 300px;
          width: auto;
          max-width: 100%;
        }
      }
      .header-right {
        padding: 20px;
        width: calc(100% - 40px);
        .btn-content {
          align-items: center;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile_size) {
  .header-box {
    padding-top: 60px;
    .header-logo {
      width: 180px;
    }

    .header-content .header-right .btn-content input {
      max-width: auto;
    }
  }
}
