// Header
$black: #111827;
$very_light_blue: #d9f0ff;
$red: #ec547b;
$light_blue: #4ecaff;
$medium_blue: #388ef4;
$blue: #1f63ea;
$light_gray: #d4d6b9;
$dark_blue: #32213a;
$white: #fff;

// Sizes
$mobile_size: 425px;
$tablet_size: 768px;
$desktop_size: 1024px;

// Fonts
$font_title: "Russo One";
$font_body: Quicksand;

@import url("https://fonts.googleapis.com/css2?family=Russo+One:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Notable:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Stick+No+Bills:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Lalezar:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Teko:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap");
