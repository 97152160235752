.blog-list-container {
  margin-top: 60px;
  padding: 20px 180px;

  @media (max-width: $desktop_size) {
    padding: 20px 40px;
  }

  .blog-container {
    margin-bottom: 40px;

    .blog-name {
      text-decoration: none;
    }
    .blog-description {
    }
  }
}

.blog-post-page {
  margin-top: 60px;
  padding: 20px 180px;

  @media (max-width: $desktop_size) {
    padding: 20px 40px;
  }

  .blog-post-long-description {
    margin-top: 40px;
    margin-bottom: 20px;

    .wmde-markdown p {
      font-family: $font_body;
      font-weight: 500;
      font-size: 18px;
    }
  }

  .blog-post-image-conatiner {
    width: 100%;
    margin-top: 40px;

    .blog-post-image {
      width: 100%;
      height: auto;
    }
  }

  .blog-post-footer {
    color: $black;
    margin-top: 50px;
    margin-bottom: 50px;

    .blog-cta {
      padding: 30px;
      text-align: center;
    }
  }
}
