.navbar {
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  background-color: $very_light_blue;
  transition: background-color 0.2s linear;
  top: 0px;
  height: 58px;
  z-index: 1;

  &.transparent {
    background-color: transparent;
    .navbar-left {
      .navbar-logo-text {
        // color: $white;
      }
    }
  }

  .navbar-left {
    a {
      display: flex;
      vertical-align: middle;
      align-items: center;
      align-content: center;
      flex-direction: row;
      text-decoration: none;

      .navbar-logo {
        height: 40px;
        width: 57px;
        padding: 10px;
      }

      .navbar-logo-text {
        font-family: $font_title;
        color: $dark_blue;
        font-weight: bold;
        font-size: 18px;
        transition: color 0.5s ease;
      }
    }
  }

  .navbar-burger-content {
    display: none;
  }

  .navbar-right {
    display: flex;
    align-items: center;
    transition: display 0.5s ease;

    .navbar-btn {
      margin-right: 15px;
      a {
        color: $dark_blue;
        text-decoration: none;
        font-weight: bold;
      }
    }

    button {
      transition: background-color 0.5s ease;
      transition: color 0.5s ease;
      font-weight: bold;
    }
  }
}

@media screen and (max-width: $tablet_size) {
  .navbar {
    z-index: 9999;

    .navbar-left,
    .navbar-center,
    .navbar-burger-content {
      z-index: 9999;
    }

    .navbar-burger-content {
      display: flex;

      .navbar-burger {
        width: 30px;
        cursor: pointer;
        margin-right: 15px;
      }
    }

    @-webkit-keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .navbar-right {
      display: none;
      transition: opacity 1s ease-out;
      opacity: 0;
      -webkit-animation: fadeIn 1s;
      animation: fadeIn 1s;

      &.open {
        z-index: 999;
        opacity: 1;

        display: flex;
        position: absolute;
        top: 0px;
        background-color: white;
        width: calc(100% - 30px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px;
        padding-top: 58px;
      }
      & > * {
        margin-top: 10px;
      }
    }
  }
}
