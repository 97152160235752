.main-container {
  width: 100%;
}

.section {
  padding: 80px 80px;
  display: flex;
  flex-direction: row;

  &.section-2 {
    background-color: $light_blue;
  }

  &.section-4 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &.section-subscribe {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .btn-content {
      width: 100%;
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-bottom: 20px;

      input {
        width: 200px;
        margin-right: 15px;
      }

      .message {
        color: $dark_blue;
      }
    }
  }

  .section-left {
    width: 50%;
  }

  .section-right {
    width: 50%;
  }

  .section-side-3 {
    width: 33.3%;
    padding: 15px;

    .section-side-img-container {
      text-align: center;
      .section-side-img {
        width: 80%;
      }
    }
  }

  h1 {
    font-size: 2.2rem;
  }
  h3 {
    font-size: 1.4rem;
    font-weight: normal;
  }

  .section-img {
    width: calc(100% - 100px);
    margin: 50px;
    // max-height: 400px;
  }
}

.terms-container {
  padding: 40px;
}

@media screen and (max-width: $mobile_size) {
  .section.section-subscribe .btn-content {
    flex-direction: column;
    input {
      margin-bottom: 10px;
      margin-right: 0px;
    }
  }
}

@media screen and (max-width: $tablet_size) {
  .section {
    flex-direction: column;
    text-align: center;
    padding: 40px;

    .section-left {
      width: 100%;
    }
    .section-right {
      width: 100%;
    }

    .section-img {
      margin: 10px;
      max-height: 300px;
      width: auto;
      max-width: 100%;
    }

    .section-side-3 {
      width: calc(100% - 30px);
    }
  }
}
